<template>
    <b-modal ref="feedback-mission" :hide-footer="accepted" :hide-header="accepted" centered>
        <template v-slot:modal-header>
            <div class="">
                Vous avez refusé cette mission
            </div>
        </template>
        <template v-if="accepted" v-slot:default>
            <b-container fluid>
                Votre demande a bien été envoyée. L'agence prendra contact avec vous dans les meilleurs délais.
            </b-container>
        </template>
        <template v-else v-slot:default>
            <b-container fluid>
                <label for="">Motif</label>
                <b-form-select v-model="reason" :options="reasons"></b-form-select>
                <b-form-textarea
                    class="mt-2"
                    v-model="comment"
                    placeholder="Commentaire"
                    rows="3"
                ></b-form-textarea>
            </b-container>
        </template>
        <template v-slot:modal-footer>
            <div class="d-flex justify-content-around w-100">
                <button class="btn btn-danger" @click="hide">Annuler</button>
                <button class="btn btn-success" :disabled="!canConfirm" @click="confirmRefuse">Valider</button>
            </div>
        </template>
    </b-modal>
</template>

<script>

export default {
    name: "FeedbackMission",
    props: {
        reasons: {
            type: Array,
            required: true
        }
    },
    computed: {
        canConfirm() {
            if (!this.reason) return false
            if (this.reason === 7 && !this.comment) return false //autre
            return true;
        }
    },
    data() {
        return {
            accepted: false,
            reason: 0,
            comment: "",
            mission: null
        }
    },
    methods: {
        show(mission, accepted) {
            this.accepted = accepted;
            this.mission = mission;
            this.$nextTick(() => {
                this.comment = "";
                this.$refs['feedback-mission'].show()
            })
        },
        hide() {
            this.$refs['feedback-mission'].hide()
        },
        confirmRefuse() {
            this.hide();
            this.$emit('reject-mission', {
                idmission: this.mission.idmission,
                reason: this.reason,
                comment: this.comment,
                mission: this.mission
            })
        }
    }
}
</script>

<style scoped>
#selectCallback {
    background: #cbf0ff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%232f3744' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>

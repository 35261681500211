<template>
    <div id="fiche-mission-page" class="pt-3 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack">
                <i class="fa fa-chevron-left"></i> retour
            </b-button>
            <div class="ml-5">Missions disponibles</div>
        </div>
        <div class="mt-5">
            <div class="row">
                <div v-for="tab in tabs" :key="tab.id" class="col-4 text-center small text-white"
                     @click="selectedTab = tab.id"
                     :class="{'text-underline': selectedTab == tab.id, 'font-weight-bold': selectedTab == tab.id}">
                    {{ tab.label }}
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <b-list-group class="text-dark listIntervention restrict-list-to-3-divs-items">
                        <div
                            class="row p-2" v-for="mission in filteredMissions"
                            :key="mission.idmission">
                            <div
                                class="col-2 rounded-left"
                                :style="`background-color:${getMissionColor(mission)}`">
                                <div v-if="isOpen(mission)"
                                     class="h-100 d-flex flex-direction-column justify-content-center align-items-center">
                                <span style="font-size: 3em">
                                    {{
                                        remainingDays(mission)
                                    }}
                                </span>
                                    <span style="font-size: 0.8em" class="text-center">jours restants</span>
                                </div>
                                <div v-else
                                     class="h-100 d-flex flex-direction-column justify-content-center align-items-center"
                                     style="opacity: 0.5;">
                                    <i class="fas fa-user fa-2x"></i>
                                </div>
                            </div>
                            <div class="col-9 bg-white">
                                <div class="row mt-2">
                                    <div class="col-12 p-0 d-flex">
                                        <div class="icon">
                                            <i class="fas fa-calendar-alt"></i>
                                        </div>
                                        <div class="w-100">
                                            <!--<option :value="2">Occasionnelle</option>-->
                                            <!--<option :value="1">Toutes les semaines</option>-->
                                            <!--<option :value="3">Toutes les deux semaines</option>-->
                                            <template v-if="mission.period_frequency == 2">
                                                Une seule fois le
                                            </template>
                                            <template v-else>
                                                À partir du
                                            </template>
                                            {{ $moment(mission.mission_date).format('DD/MM/YYYY') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 p-0 d-flex">
                                        <div class="icon">
                                            <i class="fas fa-clock"></i>
                                        </div>
                                        <div class="w-100">{{
                                                (mission.start_time || "").substr(0, 5)
                                            }}-{{ (mission.end_time || "").substr(0, 5) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 p-0 d-flex">
                                        <div class="icon">
                                            <i class="fas fa-hand-sparkles"></i>
                                        </div>
                                        <div class="w-100">{{ getActivity(mission) }}</div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 p-0 d-flex">
                                        <div class="icon">
                                            <i class="fas fa-map-marked"></i>
                                        </div>
                                        <div class="w-100">{{ mission.city }}, {{ mission.adress }}</div>
                                    </div>
                                </div>
                                <div v-if="isOpen(mission)" class="w-100 text-center mt-2">
                                    <a :href="`tel:${branchPhone}`" class="text-muted text-underline"
                                       style="font-size: 0.8em">
                                        Autre dispo ? Nous contacter</a>
                                </div>
                                <div class="row mt-2" v-if="mission.pivot.denial_comment">
                                    <div class="col-12 p-0 d-flex">
                                        <div class="icon">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                        <div class="w-100 text-muted" style="font-size: 0.8em">
                                            {{ mission.pivot.denial_comment }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div v-if="isOpen(mission)"
                                 class="bg-white rounded-right col-1 p-0 d-flex flex-direction-column justify-content-around">
                                <div
                                    @click="acceptMission(mission)"
                                    class="w-100 rounded-right text-success d-flex justify-content-center align-items-center p-2"
                                    style="font-size: 1.5em;">
                                    <i class="fas fa-check"></i>
                                </div>
                                <div
                                    @click="rejectMission(mission)"
                                    class="w-100 rounded-right text-danger d-flex justify-content-center align-items-center p-2"
                                    style="font-size: 1.5em;">
                                    <i class="fas fa-times"></i>
                                </div>
                            </div>
                            <div v-else
                                 class="rounded-right col-1 p-0 text-capitalize d-flex align-items-center justify-content-center"
                                 :style="`background-color:${getMissionColor(mission)}`">
                                <div class="d-flex flex-direction-column align-items-center ">
                                    <div v-for="(letter, index) in getLabel(mission)" :key="index"
                                         style="line-height: 1.1em; opacity: 0.5">
                                        <strong>{{ letter }}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-list-group>
                </div>
            </div>
        </div>

        <FeedbackMission
            ref="feedback-mission"
            :reasons="denialReasons"
            @reject-mission="rejectMissionCb"
        ></FeedbackMission>
    </div>
</template>

<script>
import router from '../../../router'
import FeedbackMission from './Modals/FeedbackMission'

import missionService from "@/services/missionService";
import userService from "@/services/userService";
import pointageService from "../../../services/pointageService";

export default {
    name: "MissionsDisponibles",
    components: {FeedbackMission},
    mounted() {
        pointageService
            .getBusinessActivities()
            .then(res => {
                if (res.data.etat == "KO") return;
                this.activities = res.data.data;
            });

        missionService
            .getMissions()
            .then(response => {
                if (response.data.etat === 'OK') {
                    this.missions = response.data.data?.missions;
                    this.denialReasons = response.data.data?.denial_reasons?.map(i => ({
                        value: i.iddenial_reason,
                        text: i.label
                    }));
                } else {
                    this.$rollbar.error('Erreur 3066');
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3066',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            })
            .catch((error) => {
                this.$rollbar.error('Erreur 3065', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3065',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })

        userService.getContrats()
            .then(res => {
                if (res.data.etat == "KO") return;
                const contract = res.data?.data?.[0];
                let phone = this.branchPhone;

                if (contract && contract.company_type != 'Propre' && contract.phone) {
                    phone = contract.phone;
                }

                this.branchPhone = phone;
            });
    },
    data() {
        return {
            tabs: [
                {label: 'Nouveau client', id: 1},
                {label: 'Intéressé(e)', id: 2},
                {label: 'Non intéressé(e)', id: 3},
            ],
            selectedTab: 1,
            activities: [],
            branchPhone: "0368100444",
            missions: [],
            denialReasons: [],
        }
    },
    computed: {
        filteredMissions() {
            const f1 = i => this.isOpen(i);
            const f2 = i => !this.isOpen(i) && (i.pivot.interested || i.pivot.selected)
            const f3 = i => !this.isOpen(i) && !(i.pivot.interested || i.pivot.selected)

            if (this.selectedTab === 1) return this.missions.filter(i => f1(i));
            else if (this.selectedTab === 2) return this.missions.filter(i => f2(i));
            else return this.missions.filter(i => f3(i))
        },
    },
    methods: {
        getBack() {
            router.go(-1)
        },
        acceptMission(mission) {
            missionService
                .acceptMission(mission)
                .then(res => {
                    if (res.data.etat == "OK") {
                        this.$refs['feedback-mission'].show(mission, true);
                        mission.pivot.interested = 1;
                    } else {
                        this.$rollbar.error('Erreur 3068');
                        this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                            title: 'Erreur 3068',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                })
                .catch(error => {
                    this.$rollbar.error('Erreur 3067', error);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3067',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                });
        },
        rejectMission(mission) {
            this.$refs['feedback-mission'].show(mission, false);
        },
        rejectMissionCb(reject) {
            const mission = reject.mission;
            missionService
                .rejectMission(reject)
                .then(res => {
                    if (res.data.etat == "OK") {
                        mission.pivot.interested = 0;
                    } else {
                        this.$rollbar.error('Erreur 3069');
                        this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                            title: 'Erreur 3069',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                })
                .catch(error => {
                    this.$rollbar.error('Erreur 3070', error);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3070',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                });
        },
        getMissionColor(mission) {
            const isPast = this.$moment().startOf('day') <= this.$moment(mission.mission_date);
            if ([1, 4].indexOf(mission.mission_state_idmission_state) != -1) {
                if (mission.pivot.selected) return "#28a745";
                else if (mission.pivot.interested) return "#ffc107";
                else if (mission.pivot.interested === 0) return "#e15a67";
                else if (isPast) return "#cbf0ff";
                return "#e15a67";
            } else if (mission.mission_state_idmission_state == 2) {
                if (mission.pivot.selected) return "#28a745";
                else return "#cccccc";
            } else if (mission.mission_state_idmission_state == 3) {
                if (!mission.pivot.selected) return "#cccccc";
                return "#e15a67";
            }
            return "#e15a67";
        },
        getLabel(mission) {
            const label = () => {
                if ([1, 4].indexOf(mission.mission_state_idmission_state) != -1 && mission.pivot.interested && mission.pivot.selected === null) {
                    return "Attente";
                } else if (mission.mission_state_idmission_state == 2 && mission.pivot.selected) {
                    return "Validée"
                } else if (mission.mission_state_idmission_state == 3 || (mission.mission_state_idmission_state == 2 && !mission.pivot.selected)) {
                    return "Clôturée"
                } else if (this.$moment().startOf('day') > this.$moment(mission.mission_date) || mission.pivot.selected === 0) {
                    return "Refusée"
                }
                return "Refusée";
            }

            return label().split('');
        },
        isOpen(mission) {
            return mission.pivot.interested === null && mission.pivot.selected === null && [1, 4].indexOf(mission.mission_state_idmission_state) != -1 && this.$moment().startOf('day') <= this.$moment(mission.mission_date)
        },
        getActivity(mission) {
            return this.activities.find(i => i.idbusiness_activity == mission.business_activity_idbusiness_activity)?.name
        },
        remainingDays(mission) {
            const now = this.$moment().startOf('day');
            return Math.min(this.$moment(mission.mission_date).diff(now, 'days'), 14 - Math.min(14, now.diff(this.$moment(mission.created_at), 'days')))
        }
    }
}
</script>

<style scoped>
.icon {
    width: 3em;
    text-align: center;
}

#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus {
    box-shadow: none;
}

#list {
    margin-top: 3.5rem;
}

.declarationPrestation {
    border: 1px solid #b70e0c;
    color: #b70e0c;
}

#modeList {
    max-width: 130px;
}

.bg-gray {
    background-color: #8c8988;
}

.rotate {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

</style>

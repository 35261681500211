import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    getMissions() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }

        return window.axios.get(this.baseUrl + `api-m/einstein/missions`, config);
    },
    acceptMission({idmission}) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }

        return window.axios.post(this.baseUrl + `api-m/einstein/missions/accept`, {idmission}, config);
    },
    rejectMission({idmission, reason, comment}) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }

        return window.axios.post(this.baseUrl + `api-m/einstein/missions/reject`, {idmission, reason, comment}, config);
    }
}
